import { useEffect, useState, type ReactNode } from 'react';
import { useIntl } from 'react-intl';
import cx from 'clsx';
import { Button, Checkbox } from '@repo/design-system-kit';
import { IconChevronBottomOutlined } from '@repo/monochrome-icons';
import type { BasketItem, ItemFormErrors } from '../../../../types/payment-link';
import { EditItemForm } from './edit-item-form/edit-item-form';
import styles from './selectable-item.strict-module.css';

interface SelectableItemProps {
  item: BasketItem;
  onSelect: (item: BasketItem) => void;
  onUpdate: (item: BasketItem) => void;
  isSelected?: boolean;
  disabled?: boolean;
  vatRates: number[];
  errors: ItemFormErrors;
}

export function SelectableItem({
  disabled,
  item,
  isSelected,
  onSelect,
  onUpdate,
  vatRates,
  errors,
}: SelectableItemProps): ReactNode {
  const { formatMessage, formatNumber } = useIntl();
  const [isEditOpen, setIsEditOpen] = useState(false);

  useEffect(() => {
    if (errors.length) {
      setIsEditOpen(true);
    }
  }, [errors]);

  const price = formatNumber(Number(item.unitPrice.value), {
    style: 'currency',
    currency: item.unitPrice.currency,
  });

  return (
    <div className={styles.wrapper}>
      <div className={cx(styles.product, disabled && styles.disabled)}>
        <Checkbox
          aria-checked={isSelected}
          aria-label={formatMessage({
            id: isSelected
              ? 'a11y.buttons.item-selected-aria-label'
              : 'a11y.buttons.item-unselected-aria-label',
          })}
          data-test-select-checkbox
          isDisabled={disabled}
          isSelected={isSelected}
          onChange={() => {
            onSelect(item);
          }}
        />
        <div className={styles['checkbox-label']}>
          <span className={styles.title} data-test-selectable-item-title>
            {item.title ||
              formatMessage({ id: 'payment-link.create.step-1.item.create.name.empty' })}
          </span>
          <span className={cx(styles.price, disabled && styles.disabled)} data-test-unit-price>
            {price}
          </span>
        </div>
        <Button
          aria-expanded={isEditOpen}
          aria-label={formatMessage({
            id: isEditOpen ? 'a11y.buttons.close-aria-label' : 'a11y.buttons.open-aria-label',
          })}
          className={disabled ? styles['chevron-button-disabled'] : ''}
          data-test-edit-button
          iconOnly
          isDisabled={disabled}
          onPress={() => {
            setIsEditOpen(!isEditOpen);
          }}
          size="small"
          variant="tertiary"
        >
          <IconChevronBottomOutlined
            className={cx(styles['chevron-icon'], isEditOpen && styles['chevron-icon-open'])}
          />
        </Button>
      </div>
      {isEditOpen ? (
        <EditItemForm errors={errors} item={item} onUpdate={onUpdate} vatRates={vatRates} />
      ) : null}
    </div>
  );
}
