import {
  CardAdvertisingDefault2023Flat,
  CardFlashDefault2021Flat,
  CardMetalGraphiteBlack2019Flat,
  CardMetalMineralGray2024Flat,
  CardMetalSandGold2024Flat,
  CardPlusBlack2023Flat,
  CardPlusRecycledPlasticBlack2023Flat,
  CardPlusRecycledPlasticLilac2023Flat,
  CardPlusRecycledPlasticSilver2023Flat,
  CardStandardRecycledPlastic2023Flat,
  CardVirtualDefault2017Flat,
} from './assets/illustrations/flat';

export enum BlackCardDesign {
  MetalGraphiteBlack2019 = 'metal.graphite.black.2019',
  PlusBlack2023 = 'plus.black.2023',
  PlusRecycledPlasticBlack2023 = 'plus.recycled.plastic.black.2023',
}

export enum LightCardDesign {
  AdvertisingDefault2023 = 'advertising.default.2023',
  FlashDefault2021 = 'flash.default.2021',
  MetalMineralGray2024 = 'metal.mineral.gray.2024',
  MetalSandGold2024 = 'metal.sand.gold.2024',
  PlusRecycledPlasticSilver2023 = 'plus.recycled.plastic.silver.2023',
  PlusRecycledPlasticLilac2023 = 'plus.recycled.plastic.lilac.2023',
  StandardRecycledPlastic2023 = 'standard.recycled.plastic.2023',
  VirtualDefault2017 = 'virtual.default.2017',
}

export type CardDesign = BlackCardDesign | LightCardDesign;

export enum CardLevel {
  Advertising = 'advertising',
  Flash = 'flash',
  Metal = 'metal',
  Plus = 'plus',
  Standard = 'standard',
  Virtual = 'virtual',
}

export type PhysicalCardLevel = CardLevel.Metal | CardLevel.Plus | CardLevel.Standard;

export const DEFAULT_CARD_DESIGNS: { [key in CardLevel]: CardDesign } = {
  [CardLevel.Advertising]: LightCardDesign.AdvertisingDefault2023,
  [CardLevel.Flash]: LightCardDesign.FlashDefault2021,
  [CardLevel.Metal]: BlackCardDesign.MetalGraphiteBlack2019,
  [CardLevel.Plus]: LightCardDesign.PlusRecycledPlasticSilver2023,
  [CardLevel.Standard]: LightCardDesign.StandardRecycledPlastic2023,
  [CardLevel.Virtual]: LightCardDesign.VirtualDefault2017,
};

export const FLAT_ASSETS: { [key in CardDesign]: string } = {
  [BlackCardDesign.MetalGraphiteBlack2019]: CardMetalGraphiteBlack2019Flat,
  [BlackCardDesign.PlusBlack2023]: CardPlusBlack2023Flat,
  [BlackCardDesign.PlusRecycledPlasticBlack2023]: CardPlusRecycledPlasticBlack2023Flat,
  [LightCardDesign.AdvertisingDefault2023]: CardAdvertisingDefault2023Flat,
  [LightCardDesign.FlashDefault2021]: CardFlashDefault2021Flat,
  [LightCardDesign.MetalMineralGray2024]: CardMetalMineralGray2024Flat,
  [LightCardDesign.MetalSandGold2024]: CardMetalSandGold2024Flat,
  [LightCardDesign.PlusRecycledPlasticSilver2023]: CardPlusRecycledPlasticSilver2023Flat,
  [LightCardDesign.PlusRecycledPlasticLilac2023]: CardPlusRecycledPlasticLilac2023Flat,
  [LightCardDesign.StandardRecycledPlastic2023]: CardStandardRecycledPlastic2023Flat,
  [LightCardDesign.VirtualDefault2017]: CardVirtualDefault2017Flat,
};
